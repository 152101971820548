import { addDays, endOfDay, format, setHours, subDays } from 'date-fns'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import startOfDay from 'date-fns/startOfDay'

export const TimeZones = {
  UTC: 'UTC',
  EST: 'America/New_York',
  PST: 'America/Los_Angeles',
}

export const DATE_FORMATS = {
  DATE_SIMPLE: 'yyyy-MM-dd',
  DATE_TEXT: 'MMM d, yyyy',
  DATE_SHORT_TEXT: 'MMM d',
  DATE_DEFAULT: 'M/dd/yy',
  DATETIME_SIMPLE: 'yyyy-MM-dd h:mm a',
  DATETIME_DEFAULT: 'M/dd/yy h:mm a',
  DATETIME_TEXT: 'MMM d yyyy, h:mm a',

  DATETIME_FILENAME: 'yyyy-MM-dd_HH-mm-ss',

  LOCAL_DATE_TIME: `yyyy-MM-dd'T'HH:mm`,

  TIME_DEFAULT: 'h:mm a',
}

export function formatDate(date: any, f = DATE_FORMATS.DATE_SIMPLE): string {
  try {
    return format(new Date(date), f)
  } catch (err) {
    console.error(err)
    return 'Invalid Date'
  }
}

/** Strips time and offset from Date and converts it into another timezone date.
 * e.g.
 * Input: 2022-12-15T20:49:58.935Z
 * Output: 2022-12-15T00:00:00.000Z
 */
export function convertDateToTz(date: Date, tz = TimeZones.UTC) {
  const formattedDate = formatDate(date, DATE_FORMATS.DATE_SIMPLE)
  return zonedTimeToUtc(formattedDate, tz)
}

/** Strips time and offset from DateTime and converts it into another timezone date.
 * e.g.
 * Input: 2022-12-15T20:49:58.935Z
 * Output: 2022-12-15T20:49:00.000Z
 */
export function convertDateTimeToTz(date: Date, tz = TimeZones.UTC) {
  const formattedDate = formatDate(date, 'yyyy-MM-dd hh:mm')
  return zonedTimeToUtc(formattedDate, tz)
}

/** Gets browser timezone */
export function getBrowserTz() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/** When converting a string date to an instance of Date without the timezone (i.e. 2022-01-01),
 * the browser converts it with the client's timezone and not UTC (i.e. EST / PST). This causes
 * a day shift when we format this date. This function makes sure that the timezone is UTC and injects it
 * to the Date instance.
 */
export function parseDateToUTC(date: string | Date) {
  return utcToZonedTime(date, TimeZones.UTC)
}

formatDate.FORMATS = DATE_FORMATS

export function isValidDate(date: string | number) {
  return !isNaN(Date.parse(date.toString()))
}

/** Timezone helpers */
const calcZonedDate = <T>(
  date: Date,
  tz: ValueOf<typeof TimeZones>,
  fn: (date: Date, options?: T) => Date,
  options?: T
) => {
  const inputZoned = utcToZonedTime(date, tz)
  const fnZoned = fn(inputZoned, options)
  return zonedTimeToUtc(fnZoned, tz)
}

export const zonedStartOfDay = (date: Date, timeZone: ValueOf<typeof TimeZones>) => {
  return calcZonedDate(date, timeZone, startOfDay)
}
export const zonedEndOfDay = (date: Date, timeZone: ValueOf<typeof TimeZones>) => {
  return calcZonedDate(date, timeZone, endOfDay)
}
export const zonedSetHours = (date: Date, hour: number, timeZone: ValueOf<typeof TimeZones>) => {
  return calcZonedDate(date, timeZone, (newDate) => setHours(newDate, hour))
}
export const zonedAddDays = (date: Date, days: number, timeZone: ValueOf<typeof TimeZones>) => {
  return calcZonedDate(date, timeZone, (newDate) => addDays(newDate, days))
}
export const zonedSubDays = (date: Date, days: number, timeZone: ValueOf<typeof TimeZones>) => {
  return calcZonedDate(date, timeZone, (newDate) => subDays(newDate, days))
}

/** Util functions for parsing/formatting components   */
export function formatToLocalDateTime(
  incomingDateString: string = '',
  format = DATE_FORMATS.DATETIME_TEXT,
  tz: ValueOf<typeof TimeZones> = TimeZones.UTC
) {
  if (!incomingDateString || !isValidDate(incomingDateString)) return ''
  return formatInTimeZone(new Date(incomingDateString), tz, format)
}
export function parseToTz(localDateTimeString: string, tz: ValueOf<typeof TimeZones>) {
  if (!localDateTimeString || !isValidDate(localDateTimeString)) return ''
  return zonedTimeToUtc(localDateTimeString, tz)?.toISOString() || ''
}
